import React, { Fragment } from 'react';
import { Box, Image } from 'rebass';
import styled from 'styled-components';
import mainPerson from '../../assets/mainPerson.svg';
import blobLeft from '../../assets/main_blob_left.svg';
import blobRight from '../../assets/main_blob_right.svg';
export default () => (
  <Fragment>
    <ImageBoxTop className="home-blob-1">
      <Image src={mainPerson} />
    </ImageBoxTop>
    <ImageBoxLeft className="home-blob-2">
      <Image src={blobLeft} />
    </ImageBoxLeft>
    <ImageBoxRight className="home-blob-3">
      <Image src={blobRight} />
    </ImageBoxRight>
  </Fragment>
);

const ImageBoxTop = styled(Box)`
  width: 50%;
  top: -1rem;
  right: 0;
  position: absolute;
  z-index: -1000;
`;

const ImageBoxLeft = styled(Box)`
  width: 15%;
  top: 16rem;
  position: absolute;
  z-index: -1000;

  @media screen and (min-width: 40em) {
    top: 45rem;
  }
  @media screen and (min-width: 90em) {
    top: 50rem;
  }
`;
const ImageBoxRight = styled(Box)`
  width: 13%;
  top: 31rem;
  right: 0;
  position: absolute;
  z-index: -1000;

  @media screen and (min-width: 40em) {
    top: 73rem;
  }
  @media screen and (min-width: 52em) {
    top: 80rem;
  }
`;
