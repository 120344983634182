// houses any variables to be consumed
export default {
  colors: {
    teal: '#42d9c8',
    white: '#fff',
    lightBlue: '#E8F2FF',
    mediumBlue: '#4F73DD',
    blue: '#5B7DDF',
    darkBlue: '#2F4EA9',
    red: '#ED494A',
    salmonRed: '#FC6E62',
    // textPrimary:
    // textSecondary:
    // bgPrimary:
    // bgSecondary:
    // linkPrimary:
    // linkSecondary:
  },
  fontFamily: {
    primary: 'Heiti SC, futura, sans-serif',
    secondary: 'Chypre, sans-serif',
    terciary: 'Futura, sans-serif',
    monospace: '"Courier New", Courier, monospace',
  },
  fontWeight: {
    light: '300',
    medium: '500',
    bold: '700',
    xtraBold: '900',
  },
};

// Need to think about header sizes as well
