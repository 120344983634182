import React, { Fragment, useState } from 'react';
import Amplify, { API } from 'aws-amplify';
import { Content, MainBody, Push } from './StyledComponents';
import NavBar from './NavBar';
import { BannerText } from './StyledComponents';
import awsConfig from '../aws-exports';
import FormikForm from './FormikForm';
import {
  CONTACT_NOTE,
  CONTACT_SENT_SUCCESS,
  CONACT_ADDITIONAL_INFO,
} from '../constants';
import useForm from '../customHooks/useForm';
import SuccessDialog from './SuccessDialog';
// import RecaptchaDialog from './RecaptchaDialog';
import { withBackground } from './withBackground';

Amplify.configure(awsConfig);

const ContactForm = () => {
  const [showDialog, setDialog] = useState(false);
  const { values, handleChange } = useForm();
  const sendMail = async (input, { setSubmitting, resetForm }) => {
    const body = input;
    const apiName = 'sendEmail';
    const path = '/sendmail';
    const myInit = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body,
    };
    const response = await API.post(apiName, path, myInit);
    if (response.statusCode === 200) {
      // if (true) {
      setDialog(true);
      setTimeout(() => {
        setDialog(false);
      }, 3000);
      resetForm();
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 500);

    // Create error dialog
    // Add Recaphtca
  };
  return (
    <Fragment>
      <NavBar />
      <MainBody>
        <Content>
          <BannerText fontSize={['1.5rem', '1.5rem', '1.75rem']}>
            {CONTACT_NOTE}
          </BannerText>

          <FormikForm
            values={values}
            handleSubmit={sendMail}
            handleChange={handleChange}
            set
          />
          <SuccessDialog
            successMessage={CONTACT_SENT_SUCCESS}
            additionalInfo={CONACT_ADDITIONAL_INFO}
            showDialog={showDialog}
            setDialog={setDialog}
          />
        </Content>
        <Push />
      </MainBody>
    </Fragment>
  );
};

export default withBackground(ContactForm, 'Contact');
