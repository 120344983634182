import ticketLogo from './assets/tickets_transparent.png';
import headphones from './assets/headphones_cassette_transparent.png';
//  HOME PAGE
export const RESONATE_HEADER = 'Experience sounds that';
export const RESONATE_BODY_HEADER = 'Resonate.';
export const RESONATE_BODY =
  'Peruse your curated mixtapes each week and uncover sounds that resonate with you.';
export const LISTEN_BODY_HEADER = 'Listen.';
export const LISTEN_BODY =
  'Get exposure to music by artists coming to your city in the form of premade curateable mixtapes.';
export const EXPERIENCE_BODY_HEADER = 'Experience.';
export const EXPERIENCE_BODY =
  'View your new found artists live in concert at venues near you.';

// HOME BODY DATA ITEMS
export const data = [
  {
    header: LISTEN_BODY_HEADER,
    body: LISTEN_BODY,
    image: headphones,
  },
  {
    header: RESONATE_BODY_HEADER,
    body: RESONATE_BODY,
  },
  {
    header: EXPERIENCE_BODY_HEADER,
    body: EXPERIENCE_BODY,
    image: ticketLogo,
  },
];

//  =============================================

//  NAV ITEMS
export const ABOUT = 'about';
export const CONTACT_US = 'contact';
export const HOME = 'the app';
export const LOGIN = 'Sign in';
export const LOCATION = 'Location';
export const NEWS = 'news';

export const NAV_ITEMS = [
  { name: HOME, url: '/' },
  { name: ABOUT, url: '/about' },
  { name: CONTACT_US, url: '/contact' },
  // { name: NEWS, url: '/news' }
];

//  =============================================

// FOOTER
export const SIGN_UP_INFO = 'Sign up to receive release updates';
export const FOOTER_HEADER = 'Interested?';
export const FOOTER_TAGLINE = 'a product by Unfiltered Build';
export const CONTACT_US_EMAIL = 'hello@unfilteredbuild.com';

//  =============================================

// ABOUT SECTION
export const MISSION_HEADER = 'Our Mission';
export const MISSION_ONE = 'Change the way people find new music.';
export const MISSION_TWO =
  'Provide a simple, elegant, and efficient user experience.';
export const MISSION_THREE = 'Build community and support artists.';
export const MEGHAN = 'Meghan Saunders';
export const MEGHAN_TEXT =
  "Music and tech were integral facets of Meghan's life from the beginning. As a daughter of two computer scientists, she was exposed to the world of tech from the beginning. She ended up pursuing a career in Industrial & UX design and following very closely in her parents footsteps. Music, on the other hand, served as more of a humorous outlet that the family shared to celebrate milestone or fuel a gathering. Meghan joined her first band, 'Plan B', in the sixth grade. Her 12 year-old self was painfully unaware of the fact that she was actualizing her genetic inclination for musical comedy.";
export const MEGHAN_TEXT_TWO =
  "After the short lived fame of Plan B had lapsed, Meghan's interest in music evolved into a passionate hunt for new music off the stage. She explored the slew of tools that the internet had to offer and spent many hours sampling and searching for tracks that resonated. As life evolved she was unable to devote the same time and effort to music discovery that she had in the past. She came to rely on tools that sought to provide the same richness of exposure, but always came up short. The shortcomings of these tools persist today and have given the Unfiltered Build team motive to innovate. The relationship that humans have to music is one that we still strain to understand. There is an undeniable joy that we experience when we discover music that truly resonates with us. A joy so strong that it elicits physical expression and movement. It is our goal to develop tools to help our users more effortlessly attain that intangible bliss.";
export const NIGEL_TEXT =
  'Nigel and music have a long history and he has been performing in various amalgamations of bands from Rock to Funk to Jazz as a piano/organ/synth player since the age of 9. His first "official" band, and its "official" in quotes, because this was the first rock band to play real shows for real people (not just to friends or approving parents), was The Finley-Koweek Continuum. It was comprised of his long-time high school friend, father and psychology teacher. Originally formed to perform songs written about the brain specifically the hippocampus to his high school psychology class, they continued to perform at local fairs and playing songs by the likes of Jimi Hendrix, Barenaked Ladies, Goo Goo Dolls, and Gin Blossoms until graduation.';
export const NIGEL_TEXT_TWO =
  "Nigel continued his passion for music in college, majoring in music with a minor in business. After graduation, he worked as a recording engineer, then as a music supervisor with a boutique agency and then as a music licensing manager for the largest provider of background music always keeping his passion for helping artists grow their career at the forefront of his mind. With a career switch into software development, Resonate is continuing Nigel's personal mission to find ways to help other musicians spread their craft, get discovered and grow fans.";
export const NIGEL = 'Nigel Finley';

//  =============================================

// CONTACT FORM

export const CONTACT_NAME = 'Name';
export const CONTACT_EMAIL = 'Email Address';
export const CONTACT_MESSAGE = 'Message';
export const CONTACT_NOTE = 'Drop us a note';
export const SEND_CONTACT = 'SEND';
export const CONACT_ADDITIONAL_INFO =
  'A Resonate team member will respond shortly';
export const CONTACT_SENT_SUCCESS = 'Message sent successfully!';

//  =============================================
