import React, { Fragment } from 'react';
import Footer from './Footer';
import { withBackground } from './withBackground';
import { MainBody } from './StyledComponents';
import NavBar from './NavBar';

const News = () => {
  return (
    <Fragment>
      <NavBar />
      <MainBody></MainBody>
      <Footer />
    </Fragment>
  );
};

export default withBackground(News);
