import styled from 'styled-components';
import { Button, Box, Text } from 'rebass';

export const MainBody = styled.main`
  min-height: 100vh;

  margin: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: -50px;
`;

export const Content = styled.div`
  ${'' /* flex: 1 0 auto; */}
  ${'' /* min-height: 100%;
  margin-bottom: -50px; */}
`;
export const BannerText = styled(Text)`
  color: ${props => props.theme.colors.darkBlue};
  margin-bottom: 1rem;
`;

export const StyledInput = styled.input`
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.darkBlue};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.075);
  border-radius: 0px;
  padding: 0.75rem;
  font-size: 16px;
  width: 75%;
  display: block;
  border: 1px solid #ffffff;
  border-color: ${props =>
    props.className.includes('error')
      ? props.theme.colors.red
      : props.theme.colors.white};

  :focus {
    border-color: #2f4ea9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
  ::placeholder {
    font-family: ${props => props.theme.fontFamily.primary};
    opacity: 0.75;
    color: ${props => props.theme.colors.darkBlue};
  }
`;

export const StyledTextArea = styled.textarea`
  font-family: ${props => props.theme.fontFamily.primary};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.075);
  border-radius: 0px;
  padding: 0.5rem;
  font-size: 16px;
  width: 75%;
  display: block;
  border: 1px solid #ffffff;
  border-color: ${props =>
    props.className.includes('error')
      ? props.theme.colors.red
      : props.theme.colors.white};

  :focus {
    border-color: #2f4ea9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }

  ::placeholder {
    font-family: ${props => props.theme.fontFamily.primary};
    opacity: 0.75;
    color: ${props => props.theme.colors.darkBlue};
  }
`;

export const StyledButton = styled(Button)`
  max-width: 150px;
  min-width: 5rem;
  ${'' /* margin: 20px 0; */}
  padding: 12px 20px;
  border-style: none;
  border-radius: 0px;
  background-color: ${props => props.theme.colors.red};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 1rem;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-family: ${props => props.theme.fontFamily.terciary};
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`;

export const InputFeedback = styled(Box)`
  color: ${props => props.theme.colors.red};
  margin-top: 0.5rem;
  font-size: 1rem;
  }`;

export const Push = styled.div`
  height: 50px;
`;

Content.displayName = 'Content';
BannerText.displayName = 'BannerText';
InputFeedback.displayName = 'InputFeedback';
MainBody.displayName = 'MainBody';
StyledButton.displayName = 'StyledButton';
StyledInput.displayName = 'StyledInput';
StyledTextArea.displayName = 'StyledTextArea';
Push.displayName = 'PushFooter';
