import React, { Fragment } from 'react';
import { Flex, Image, Text } from 'rebass';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../assets/wordmark.svg';
import { data, RESONATE_HEADER } from '../constants';
import { BannerText, Content, MainBody, Push } from './StyledComponents';
import ResonateCard from './ResonateCard';
import NavBar from './NavBar';
import { withBackground } from './withBackground';
import ArrowIcon from './icons/ArrowIcon';
import { checkIfMobile } from '../util/helpers';

const Home = () => {
  const isMobile = checkIfMobile();
  return (
    <Fragment>
      <NavBar />
      <MainBody>
        <Content>
          <ResonateHeader mt={[2, 3, 5]} minHeight={['20vh', '70vh', '70vh']}>
            <BannerText fontSize={[4, 4, 6]} maxWidth={['50%', '50%', 'auto']}>
              {RESONATE_HEADER}
            </BannerText>
            <Image src={logo} width={[2 / 5]} />
          </ResonateHeader>
          {/* ONLY SHOW THIS IF NOT A MOBILE VIEW */}

          {!isMobile && (
            <ScrollLink
              activeClass="active"
              to="about-resonate"
              spy={true}
              smooth={true}
              offset={0}
              duration={900}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
              zIndex="1000"
            >
              <Flex>
                <Text color="red" fontWeight="bold">
                  SCROLL
                </Text>
                <ArrowIcon />
              </Flex>
            </ScrollLink>
          )}

          <DescriptionWrapper
            flexDirection="column"
            mt={[3, 5, 5]}
            pl={['0', '8rem', '8rem']}
          >
            <div className="container" id="about-resonate">
              <Image
                className="image1"
                width={['4rem', '8rem', '10rem']}
                height={'auto'}
                src={data[0].image}
                borderRadius={4}
                m={[1, 2, 3, 4]}
                justifySelf={'center'}
                alignSelf={'stretch'}
              />

              <Image
                className="image2"
                width={['4rem', '8rem', '10rem']}
                src={data[2].image}
                borderRadius={4}
                justifySelf={'center'}
                alignSelf={'center'}
                m={[1, 2, 3, 4]}
              />
              <ResonateCard
                className="card1"
                key={data[0].header}
                body={data[0].body}
                header={data[0].header}
                image={data[0].image}
              />
              <ResonateCard
                className="card2"
                key={data[1].header}
                body={data[1].body}
                header={data[1].header}
                image={data[1].image}
              />
              <ResonateCard
                className="card3"
                key={data[2].header}
                body={data[2].body}
                header={data[2].header}
                image={data[2].image}
              />
            </div>
          </DescriptionWrapper>
        </Content>
        <Push />
      </MainBody>
    </Fragment>
  );
};

const DescriptionWrapper = styled(Flex)``;
const ResonateHeader = styled(Flex)`
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
`;

export default withBackground(Home, 'Home');
