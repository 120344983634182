import React from 'react';

const SvgComponent = props => (
  <div style={{ marginTop: '-5px' }}>
    <svg height={32} width={32} {...props}>
      <path
        d="M14.77 23.795L5.185 14.21a2.265 2.265 0 010-3.195l.8-.801a2.266 2.266 0 013.194 0l7.315 7.315 7.316-7.315a2.266 2.266 0 013.194 0l.8.801a2.265 2.265 0 010 3.195l-9.587 9.585a2.242 2.242 0 01-1.723.647 2.247 2.247 0 01-1.724-.647z"
        fill="#ed494a"
      />
    </svg>
  </div>
);

export default SvgComponent;
