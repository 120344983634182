import React from 'react';
import { Box, Flex, Image, Link, Text } from 'rebass';
import styled from 'styled-components';
import { CONTACT_US_EMAIL, FOOTER_TAGLINE } from '../constants';
import logo from '../assets/logo_one_color.svg';
import unfilteredLogo from '../assets/UnfilteredBuild_Logo_Blue.png';
import { checkIfMobile } from '../util/helpers';

const ContactFooter = () => {
  const isMobile = checkIfMobile();

  return (
    <ContactFooterWrapper
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      id="contact-us"
    >
      <Flex flexDirection="column" alignItems="flex-end">
        {isMobile && (
          <Flex flexDirection="column" alignItems="flex-end">
            <Image src={logo} width={['5.5rem', '7.5rem']} />
            <FooterText alignSelf="center" fontSize={['.8rem', '1.2rem']}>
              {FOOTER_TAGLINE}
            </FooterText>
          </Flex>
        )}
        {!isMobile && (
          <Flex>
            <Image src={logo} width={['5.5rem', '7.5rem']} />
            <FooterText alignSelf="center" fontSize={['.8rem', '1.2rem']}>
              {FOOTER_TAGLINE}
            </FooterText>
          </Flex>
        )}

        <MailTo href={`mailto:${CONTACT_US_EMAIL}`} fontSize={['.8rem', 2, 3]}>
          {CONTACT_US_EMAIL}
        </MailTo>
      </Flex>
      <Box>
        <UnfilteredImage
          src={unfilteredLogo}
          width="70px"
          height="70px"
          ml={[2, 3, 4]}
        />
      </Box>
    </ContactFooterWrapper>
  );
};

const ContactFooterWrapper = styled(Flex)`
  margin-bottom: 4rem;
  margin-top: 4rem;
  color: ${props => props.theme.colors.darkBlue};
  font-family: ${props => props.theme.fontFamily.terciary};
`;

const MailTo = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.darkBlue};
  font-weight: ${props => props.theme.fontWeight.medium};
  opacity: 0.7

  :hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.darkBlue};
    opacity: 1;
  }
`;
const FooterText = styled(Text)`
  opacity: 0.85;
  margin-left: 0.5rem;
`;

const UnfilteredImage = styled(Image)``;

export default ContactFooter;
