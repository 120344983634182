import React, { Fragment } from 'react';

import { Content, MainBody, Push } from './StyledComponents';
import NavBar from './NavBar';
import styled from 'styled-components';
import { withBackground } from './withBackground';

import {
  MISSION_HEADER,
  MISSION_ONE,
  MISSION_TWO,
  MISSION_THREE,
  MEGHAN,
  MEGHAN_TEXT,
  MEGHAN_TEXT_TWO,
  NIGEL,
  NIGEL_TEXT_TWO,
  NIGEL_TEXT,
} from '../constants';
import { Flex, Text } from 'rebass';

const About = () => {
  return (
    <Fragment>
      <NavBar />
      <MainBody>
        <Content>
          <AboutWrapper
            mr={['1rem', '8rem', '12rem']}
            lineHeight={['1.6', '1.2', '1.2']}
          >
            <MissionHeader>{MISSION_HEADER}</MissionHeader>
            <OurMission fontSize={['1rem', '1.25rem', '1.25rem']}>
              {MISSION_ONE}
            </OurMission>
            <OurMission fontSize={['1rem', '1.25rem', '1.25rem']}>
              {MISSION_TWO}
            </OurMission>
            <OurMission fontSize={['1rem', '1.25rem', '1.25rem']}>
              {MISSION_THREE}
            </OurMission>
            <ContentHeader>{NIGEL}</ContentHeader>
            <ContentWrapper>
              <ContentText>{NIGEL_TEXT}</ContentText>
              <ContentText>{NIGEL_TEXT_TWO}</ContentText>
            </ContentWrapper>
            <ContentHeader>{MEGHAN}</ContentHeader>
            <ContentWrapper>
              <ContentText>{MEGHAN_TEXT}</ContentText>
              <ContentText>{MEGHAN_TEXT_TWO}</ContentText>
            </ContentWrapper>
          </AboutWrapper>
        </Content>
        <Push />
      </MainBody>
    </Fragment>
  );
};

const AboutWrapper = styled(Flex)`
  flex-direction: column;
  font-family: ${props => props.theme.fontFamily.primary};
  ${'' /* line-height: 1.2; */}
`;

const MissionHeader = styled(Text)`
  color: ${props => props.theme.colors.blue};
  font-weight: ${props => props.theme.fontWeight.xtraBold};
  font-size: 1.75rem;
  padding: 4rem 0 1rem 0;
`;
const OurMission = styled(Text)`
  color: ${props => props.theme.colors.darkBlue};
  font-weight: ${props => props.theme.fontWeight.bold};
`;
const ContentHeader = styled(Text)`
  color: ${props => props.theme.colors.red};
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: 4rem 0 1rem 0;
  font-size: 1.75rem;
`;
const ContentText = styled.p`
  color: ${props => props.theme.colors.darkBlue};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 1rem;
`;
const ContentWrapper = styled.div`
  line-height: 1.6;
`;

export default withBackground(About, 'About');
