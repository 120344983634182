import React, { Fragment } from 'react';
import { Box, Image } from 'rebass';
import styled from 'styled-components';
import blobRight from '../../assets/about_blob_a.svg';
import blobLeft from '../../assets/about_blob_b.svg';

export default () => (
  <Fragment>
    <ImageBoxRight>
      <Image src={blobLeft} />
    </ImageBoxRight>
    <ImageBoxLeft>
      <Image src={blobRight} />
    </ImageBoxLeft>
  </Fragment>
);

const ImageBoxRight = styled(Box)`
  width: 5%;
  bottom: -30rem;
  position: absolute;
  z-index: -1000;
`;

const ImageBoxLeft = styled(Box)`
  width: 10%;
  bottom: -30%;
  right: 0;
  position: absolute;
  z-index: -1000;
`;
