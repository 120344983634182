import React from 'react';
import styled from 'styled-components';
import EmailSubscribe from './EmailSubscribe';
import ContactFooter from './ContactFooter';

const Footer = () => {
  return (
    <Wrapper>
      <EmailSubscribe />
      <ContactFooter />
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  margin-top: 4rem;
  height: 50px;
`;
export default Footer;
