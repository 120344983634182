import React, { Component, Fragment } from 'react';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import { Box } from 'rebass';
import backgrounds from './backgrounds';
import Footer from './Footer';

export const withBackground = (WrappedComponent, pageName) => {
  const BackgroundComponent = backgrounds[pageName];
  return class extends Component {
    render() {
      return (
        <Fragment>
          <GlobalStyle />
          <BackgroundComponent />
          <MainWrapper ml={[3, 3, 5]} mr={[3, 3, 5]} mt={[2]}>
            <WrappedComponent />
            <Footer />
          </MainWrapper>
        </Fragment>
      );
    }
  };
};

const GlobalStyle = createGlobalStyle`
body {
  background-color: #E8F2FF;
}
`;

const MainWrapper = styled(Box)``;
