import HomeBackground from './HomeBackground';
import AboutBackground from './AboutBackground';
import ContactBackground from './ContactBackground';

const background = {
  Home: HomeBackground,
  About: AboutBackground,
  Contact: ContactBackground,
};
export default background;
