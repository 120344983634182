import React, { Fragment } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import { Box, Button } from 'rebass';
import styled from 'styled-components';

const SuccessDialog = ({
  successMessage,
  additionalInfo,
  showDialog,
  setDialog,
}) => {
  return (
    <Fragment>
      <DialogOverlay
        style={{
          background: 'hsla(0, 100%, 100%, 0.9)',
        }}
        isOpen={showDialog}
        onDismiss={() => setDialog(false)}
      >
        <DialogContent
          style={{
            boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 0 2rem 0',
          }}
        >
          {/* PUT THE LOGO HERE */}
          <Box alignSelf="flex-end">
            <CloseButton
              aria-hidden
              // w={[1]}
              onClick={() => setDialog(false)}
              variant="outline"
            >
              x<VisuallyHidden>Close</VisuallyHidden>
            </CloseButton>
          </Box>
          <h2>{successMessage}</h2>
          <h4>{additionalInfo}</h4>
        </DialogContent>
      </DialogOverlay>
    </Fragment>
  );
};

const CloseButton = styled(Button)`
  background-color: ${props => props.theme.colors.white};
  ${'' /* border: 1px solid ${props => props.theme.colors.mediumBlue}; */}
  color: grey;
`;

export default SuccessDialog;
