import React from 'react';
import { Box, Card, Text } from 'rebass';
import styled from 'styled-components';

const ResonateCard = ({ body, className, header }) => {
  return (
    <Card className={className} maxWidth="20rem">
      <Text
        color="red"
        fontSize={[2, 4, 5]}
        fontFamily="Futura"
        pb={['.1rem', '.5rem']}
      >
        {header}
      </Text>
      <Box>
        <MainText
          fontWeight="bold"
          color="darkBlue"
          fontSize={['.75rem', 2, 3]}
          lineHeight={['normal', '1.8rem']}
        >
          {body}
        </MainText>
      </Box>
    </Card>
  );
};

const MainText = styled(Text)`
  font-family: ${props => props.theme.fontFamily.primary};
`;

export default ResonateCard;
