import { useState } from 'react';

const useForm = () => {
  const [values, setValues] = useState({});

  const handleChange = event => {
    console.log('event', event.target.name);
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  return {
    handleChange,
    values,
    setValues
  };
};

export default useForm;
