import React, { Fragment } from 'react';
import { Box, Image } from 'rebass';
import styled from 'styled-components';
import blobTopRight from '../../assets/contact_us_top_right.svg';
import blobLeft from '../../assets/contact_us_blob_left.svg';
import blobBottom from '../../assets/contact_us_blob_bottom.svg';

export default () => (
  <Fragment>
    <ImageBoxTopRight>
      <Image src={blobTopRight} />
    </ImageBoxTopRight>
    <ImageBoxLeft>
      <Image src={blobLeft} />
    </ImageBoxLeft>
    <ImageBoxBottom className="contact-blob-bottom">
      <Image src={blobBottom} />
    </ImageBoxBottom>
  </Fragment>
);

const ImageBoxTopRight = styled(Box)`
  top: -1rem;
  right: 0;
  position: absolute;
  z-index: -1000;
`;
const ImageBoxLeft = styled(Box)`
  width: 5%;
  top: 15rem;
  position: absolute;
  z-index: -1000;
`;

const ImageBoxBottom = styled(Box)`
  width: 15%;
  top: 74rem;
  right: 0;
  position: absolute;
  z-index: -1000;

  @media screen and (min-width: 40em) {
    top: 67rem;
  }
  @media screen and (min-width: 64em) {
    top: 63rem;
  }
  @media screen and (min-width: 90em) {
    top: 58rem;
  }
`;
