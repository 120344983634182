import React, { useState } from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { SIGN_UP_INFO, FOOTER_HEADER } from '../constants';
import { StyledInput, StyledButton } from './StyledComponents';

const EmailSubscribe = () => {
  const [email, setEmail] = useState('');

  return (
    // <div id="mc_embed_signup">
    <FormWrapper>
      <Header>{FOOTER_HEADER}</Header>
      <form
        action="https://resonatetogether.us20.list-manage.com/subscribe/post?u=1ef6764ce5d59418e20b31b57&amp;id=5a254207bf"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
        autoComplete="off"
      >
        {/* <div id="mc_embed_signup_scroll"> */}
        <div>
          <SignUpLabel htmlFor="mce-EMAIL">{SIGN_UP_INFO}</SignUpLabel>
          <InputWrapper mt={['1rem']}>
            <StyledInput
              type="email"
              value={email}
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="your email address"
              required
              onChange={e => setEmail(e.target.value)}
            />
            <StyledButton
              type="submit"
              value="SUBMIT"
              name="submit"
              id="mc-embedded-subscribe"
              className="button"
            >
              SUBMIT
            </StyledButton>
          </InputWrapper>
          <div
            style={{
              position: 'absolute',
              left: '-5000px',
              ariaHidden: true,
            }}
          >
            <input
              type="text"
              name="b_1ef6764ce5d59418e20b31b57_5a254207bf"
              tabIndex="-1"
              // value=""
            />
          </div>
        </div>
      </form>
    </FormWrapper>
  );
};

const FormWrapper = styled.div``;
const Header = styled.h1`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.darkBlue};
  font-family: ${props => props.theme.fontFamily.terciary};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const InputWrapper = styled(Flex)``;
const SignUpLabel = styled.label`
  color: ${props => props.theme.colors.darkBlue};
  font-family: ${props => props.theme.fontFamily.terciary};
  opacity: 0.75;
  font-size: 1.15rem;
  font-weight: ${props => props.theme.fontWeight.medium};
`;

export default EmailSubscribe;
