import React from 'react';
import { Router } from '@reach/router';

import './App.css';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import Home from './components/Home';
import News from './components/News';
import About from './components/About';
import ContactForm from './components/ContactForm';
import '@reach/dialog/styles.css';
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Home path="/" />
        <About path="/about" />
        <ContactForm path="/contact" />
        <News path="/news" />
      </Router>
    </ThemeProvider>
  );
};

export default App;
