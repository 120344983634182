import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Flex } from 'rebass';
import styled from 'styled-components';

import { CONTACT_NAME, CONTACT_EMAIL, CONTACT_MESSAGE } from '../constants';
import {
  StyledButton,
  StyledInput,
  StyledTextArea,
  InputFeedback,
} from './StyledComponents';
// import Recaptcha from './RecaptchaDialog';

const FormikForm = ({ handleSubmit }) => {
  return (
    <Formik
      initialValues={{ email: '', name: '', message: '' }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required('Email is Required'),
        name: Yup.string().required('Your Name is Required'),
        message: Yup.string().required('A Message is Required'),
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <FormGroup onSubmit={handleSubmit}>
            <InputGroup>
              <StyledLabel htmlFor="name">{CONTACT_NAME}</StyledLabel>
              <StyledInput
                id="name"
                name="name"
                placeholder="your name"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.name && touched.name && (
                <InputFeedback>{errors.name}</InputFeedback>
              )}
            </InputGroup>
            <InputGroup>
              <StyledLabel htmlFor="email">{CONTACT_EMAIL}</StyledLabel>
              <StyledInput
                id="email"
                name="email"
                placeholder="your email address"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.email && touched.email
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.email && touched.email && (
                <InputFeedback>{errors.email}</InputFeedback>
              )}
            </InputGroup>
            <InputGroup>
              <StyledLabel htmlFor="message">{CONTACT_MESSAGE}</StyledLabel>
              <StyledTextArea
                id="message"
                name="message"
                placeholder="a note to the resonate team"
                type="text"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                rows="10"
                className={
                  errors.message && touched.message
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.message && touched.message && (
                <InputFeedback>{errors.message}</InputFeedback>
              )}
            </InputGroup>
            {/* <Recaptcha /> */}

            <StyledButton type="submit" disabled={isSubmitting}>
              SEND
            </StyledButton>
          </FormGroup>
        );
      }}
    </Formik>
  );
};

// ADD Formik Classes within here
const FormGroup = styled.form``;

const InputGroup = styled(Flex)`
  flex-direction: column;
  padding-bottom: 2rem;
`;

const StyledLabel = styled.label`
  color: ${props => props.theme.colors.darkBlue};
  margin-bottom: 1rem;
  font-size: 1rem;
`;

export default FormikForm;
