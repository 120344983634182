// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://uykbms3hmjbqfpyw6fwvjrpjti.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-de7tsa4jv5fwpkuenb7ry2sveq",
    "aws_cloud_logic_custom": [
        {
            "name": "sendEmail",
            "endpoint": "https://fasm1b7lma.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        }
    ],
    "aws_content_delivery_bucket": "www-dev.resonatetogether.io-master",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://www-dev.resonatetogether.io-master.s3-website-us-west-2.amazonaws.com"
};


export default awsmobile;
